.contact {
  width: 100%;
  background: var(--color-orange);

  padding: 0 32px 16px;
}

.contact__wrapper {
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  padding-top: 4em;
  padding-bottom: 0;
  margin: 0 auto;
  color: var(--color-white);

  cursor: var(--cursor-white);
}

.contact__main {
  display: flex;
  justify-content: space-around;

  font-size: 32px;
  line-height: 44px;
  font-weight: 400;

  margin-bottom: 60px;
}

.contact__info {
  flex: 1;
}

.contact__info--headline {
  width: 60%;
  margin-bottom: 12px;
}

.contact__info--email{
  font-size: 20px;
  line-height: 28px;
}

.contact--info--email--text{
  display: flex;
  width: fit-content;
  column-gap: 1rem;
}

.contact--info--email--text > p {
  text-decoration: underline;
}

#email--copied {
  text-decoration: none;
}

.contact__form {
  flex: 1;
  display: flex;
  justify-content: stretch;

}

.contact__form form {
  width: 100%;

}

.contact__form input {
  display: flex;
  width: 100%;
  background-color: var(--color-orange);
  color:var(--color-white);
  border: none;
  border-bottom: 1px solid var(--color-white);
  padding: 16px;
  margin-bottom: 16px;

  font-size: 20px;
  line-height: 28px;
}

.contact__form input::placeholder {
  color: var(--color-white)
}
.contact__form input:focus {
  outline: none;
}

.contact__container--btn {
  width: 100%;
  margin-top: 36px;
}

.contact__container--btn button{
  width: 100%;
  height: 53px;
  cursor: var(--cursor-orange);
  border: none;
}

.contact__devise--logo{
  width: 100%;
}

.contact__devise--logo > svg {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.contact__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  
  padding-top: 1rem;
  padding-bottom: .5rem;
  border-top: 1px solid var(--color-white) ;
  z-index: 20;
}

.contact__footer a {
  padding-left: 0;
  color: var(--color-white)
}


@media screen and (max-width: 820px) {
  .contact {
    padding: 4rem 16px 16px;
  }
  .contact__container--formInput input {
    width: 100%;    
    font-size: 16px;
    line-height: 24px;
  }

  .contact__info--headline {
    width: 100%;
  }

  .contact__container--btn button {
    font-size: 16px;
  }

}

@media screen and (max-width: 480px) {
  .contact__main {
    flex-direction: column;
    row-gap: 2rem;
  }

  .contact__info--headline {
    width: 100%;
  }

    .contact__container--btn button {
      width: 100%;
    }
    
    .contact__footer {
      flex-direction: column;
      margin-top: 5rem;
    }

    
    .contact-footer_email{
      flex-direction: column;
    }
  
  .contact__footer {
    margin-top: 0;
  }
}