.clientCarousel {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  
  padding: 5rem 0 10rem 32px ;
  margin: 0 auto;
  background:var(--color-bg);
  scroll-snap-stop: normal;

  position: relative;
}

.clientCarousel__container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  overflow: hidden;
}

.clientCarousel__selector{
  flex: .5;
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;
}

.clientCarousel__selector--list {
  flex: 1;
  /* width: 90%; */
  display: flex;
  flex-direction: column;

}

.selector__list--header {
  color: var(--color-white);
  margin-bottom: 16px;
  font-size: 20px;
}
.selector__list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-top: .5px solid #4b4b4b;
  cursor: url('../../assets/arrows/white_circle.svg') 10 10, auto;
  z-index: 1;
  position: relative;

  padding: 8px 0;
  column-gap: 1rem;
}

.selector__list.active  {
  border: none;
}

.selector__list.active::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-white);
  transform-origin: left;
  animation: borderAnimate .5s linear forwards;
  z-index: 1;
}

@keyframes borderAnimate {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scale(1);
  }
}


.selector__list p {
  width: 100%;
  height: 100%;
  color:#919191;
  padding-top: .5rem;
  text-align: left;
  white-space: nowrap;
}

.selector__list div {
  width: 8px;
  height: 8px;
  background: var(--color-white);
  border-radius: 50%;
  opacity: 0;
}

.selector__list.active p, 
.selector__list.active div {
  color: var(--color-white);
  opacity: 1;
}

.clientCarousel__carousel {
  width: 70%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}



.clientCarousel__carousel--label{
  height: 28px;
  color: var(--color-white);
  font-size: 20px;
  line-height: 28px;
  font-style: normal;
  font-weight: 400;
  font-family: var(--font-family), Helvetica, Arial, sans-serif;
  margin-bottom: 12px;
}

.clientCarousel__carousel--box {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 600px;
  scroll-behavior: smooth;
  column-gap: 2rem;
  position: relative;
  overflow-y: hidden;
  overflow-x: scroll;

  -webkit-overflow-scrolling: touch;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.clientCarousel__carousel--box::-webkit-scrollbar {
  display: none;
}
.clientCarousel__carousel--box.mouseLeft:hover {
  cursor: url('../../assets/arrows/orangeLeft.svg'), auto;
}
.clientCarousel__carousel--box.mouseRight:hover {
  cursor: url('../../assets/arrows/orangeRight.svg'), auto;
}
.clientCarousel__carousel--box.mouseCenter:hover {
  cursor: url('../../assets/arrows/orangeDot.svg') 12 12, auto;
}
.clientCarousel__carousel--box::-webkit-scrollbar {
  display: none;
}

.carouselCard {
  min-width: 60%;
  display: flex;
  height: fit-content;

  flex-direction: column;
  justify-content: center;

  -webkit-overflow-scrolling: touch;
  overflow-y: visible;
  position: relative;
  row-gap: 1rem;

}

.carouselCard--img {
  width: 100%;
  object-fit: cover;
  transition:all 0.5s ease;
}

.carouselCard--text-liner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 3rem;
  text-wrap: wrap;
}

.carouselCard--logo {
  height: 100px;
  max-height: 100px;
  object-fit: contain;
}

.carouselCard--text-liner p {
  flex: 1;
  font-family: var(--font-family), Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  overflow: visible;
}

.clientCarousel__footer {
  width: inherit;
  max-width: var(--max-width);
  height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background: var(--color-bg);
  border-top: .5px solid #fff;
  z-index: 1;
  margin: 0 auto;
  opacity: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  transition: 1s;
}

.clientCarousel__footer:hover {
  cursor: url(../../assets/arrows/white_circle.svg) 15 15, auto;
}

.clientCarousel__footer--square {
  flex: 1;
  display: flex;
  /* justify-self: flex-start; */
  /* align-items: flex-start; */
  padding-left: 32px;
  column-gap: 1rem;
}

.clientCarousel__footer--square > div {
  width: 12px;
  height: 12px;
  background: var(--color-white);
}

.clientCarousel__footer p {
  flex: 1;
  color: var(--color-white);
  font-family: var(--font-family);
  text-align: left;
  font-weight: 600;
  font-style: italic;
  font-size: 16px;
  line-height: 20px;

}

.clientCarousel__footer--content {
  flex: 1;
  display: flex;
  height: fit-content;
  justify-content: flex-end;
  align-items: center;
  padding-right: 32px;
  margin: 0;
}

.clientCarousel__footer--content p {
  text-align: right;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-style: normal;
  margin: 0;
}

.clientCarousel__footer--content p span{
  margin-left: 1rem;
  font-weight: 800;
}

.clientCarousel__footer--content a {
  margin: 0rem;
  text-decoration: none;
  color: var(--color-white);
  font-family: var(--font-family);
  font-size: 14px;
}

.clientCarousel__footer--content a:hover ~
img  {
  animation: shakeArrow 1s infinite ease;
}

@keyframes shakeArrow {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(5px, -5px);
  }
  100% {
    transform: translate(0, 0);
  }
}


@media screen and (max-width: 1024px) {
  .clientCarousel {
    padding: 5rem 0 0rem 32px ;

  }
  .clientCarousel__container {
    flex-direction: column;
    width: 100%;
  }

  .clientCarousel__selector {
    flex-direction: column;
    width: 100%;
    margin-bottom: 2rem;
  }

  .selector__list--header {
    display: none;
  }
  
  .clientCarousel__selector--list{
    width: 100%;
    scrollbar-width: none;
  }
  
  .clientCarousel__selector--list::-webkit-scrollbar {
    display: none;
  }
  .selector__list p {
    text-align: center;
  }

  .selector__list div {
    display: none;
  }
  .clientCarousel__selector--list {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .clientCarousel__selector--list .selector__list {
    margin: 0 1rem 0 0;
  }
  
  .clientCarousel__carousel {
    width: 100%;
    height: fit-content;

  }

  .carouselCard {
    min-width: 80%;
    height: 400px;
    max-height: 400px;
    justify-content: flex-start;

  }

  .clientCarousel__carousel--box {

  }

.carouselCard--text-liner {
  align-items: center;
}

  .clientCarousel__carousel--label {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .clientCarousel {

    padding-bottom: 0rem;
  
  }
  .carouselCard--text-liner{
    column-gap: 1rem;
    flex-direction: column;
    align-items: flex-start;

  }

  .clientCarousel__selector{
    display: none;
  }

  .carouselCard--text-liner > img {
    height: 80px;
  }
  .carouselCard--text-liner > p {
    flex: 1;
    width: 100%;
    z-index: 10;

  }


  .clientCarousel__footer {
    height: 65px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 0;
  }
  
  .clientCarousel__footer--square {
    padding: 0 0 0 16px;
  }

  .clientCarousel__footer--content {
    padding: 0 16px 0 0;
    /* justify-content: space-between; */
  }
  .clientCarousel__footer--content > p {
    display: none;
  }
}