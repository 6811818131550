.aboutUs__container{
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  max-width: var(--max-width);
  color: var(--color-white);
  font-family: var(--font-family);
  padding-left: 32px;

  column-gap: 10%;
  padding-right: 20%;
  padding-top: 200px;
  padding-bottom: 122px;
  margin: 0 auto;

  position: relative;
}
.aboutUs__text__container{
  width: 45%;
  margin-bottom: 28px;
  /* z-index: 10; */
}

.aboutUs__headline {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  position: relative;

}

.aboutUs__headline--p{
  text-indent: 4em;
  font-size: 44px;
  line-height: 52px;

}

.headline--about {
  /* display: inline; */
  font-size: 12px;
  color: #FE4409;
  line-height: 16px;
  white-space: nowrap;

  position: absolute;
  /* top:0;
  left: 0; */

  /* border: 1px solid red */
}

.aboutUs__text--p {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  opacity: .5;
}


.aboutUs__grids {
  height: 100%;
  width: 100%;

  position: absolute;
  inset: 0;

}

.aboutUs__grids--canvas {
  height: 100%;
  width: 100%;

  z-index: -1;
}

@media screen and  (max-width: 820px) {
  .aboutUs__container{
    flex-direction: column;
    padding: 0 16px;
    column-gap: 0;

    row-gap: 16px;
  }

  .aboutUs__headline--p {
    text-indent: 3em;
  }

  .aboutUs__text__container{
    width: 100%;
  }

  
}

@media screen and  (max-width: 480px) {
  .aboutUs__container{
    padding-left: 16px;
  }
  .aboutUs__headline {
    font-size: 32px;
    line-height: 40px;
  }

  .aboutUs__text--p {
    font-size: 18px;
    line-height: 26px;
  }
.first_para{
  font-size: 24px;
  line-height: 32px;
}
}