.navbar {
  width: 100vw;
  height: 75px;
  background-color: var(--color-bg);

  position:fixed;
  inset: 0px 0px 0px 0px;
  z-index: 100;
}

.navbar__wrapper {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 16px 0px 0;

  background-color: var(--color-bg);
  display: flex;
  justify-content: space-between;
  align-items: center;

  
  opacity: 0;
  animation: showBar 500ms linear forwards;  
  animation-delay: 750ms;
}

@keyframes showBar {
  0%{opacity: 0;}
  100%{opacity: 1;}
}

.content__location {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  position: relative;
}

.content__location--circle {
  width: 12px;
  height: 12px;
  background: var(--color-white);
  border-radius: 50%;
  margin-right: .5rem;
}

.content__location--hire {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: fit-content;
  
}

.content__location--hire-p{
  font-family: var(--font-family), Helvetica, Arial, sans-serif;
  color: var(--color-white);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-right: 8px;
  white-space: nowrap;
}

.navbar__marquee {
  height: 16px;
  width: 300px;
  animation: blink .5s infinite ease-in
}

@keyframes blink {
  0% {
    border-right: .5px solid #fff
  }
  100% {
    border: none
  }
}


.navbar__marquee__container {
  overflow: hidden;
}

.navbar__marquee-inner span {
  float: left;
  width: 50%
}

.navbar__marquee-block{
  --total-marquee-items: 17;
  height: 16px;
  width: calc(85px * (var(--total-marquee-items)));
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.navbar__marquee-inner {
  display: inline-flex;
  width: 200%;
  position: absolute;
  top: 3px;
}

.navbar__marquee-inner span {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.navbar__marquee-inner p{
  font-size: 12px;
  color: var(--color-white);
  font-family: var(--font-family);
}

.navbar__marquee-inner.to-left{
  animation: marqueeLeft 30s linear infinite;
}

.navbar__marquee-item{
  width: max-content;
  height: 16px;
  transition: all .2s ease-out;
}

@keyframes marqueeLeft{
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

.navbar__logo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  

}

.logo__svg{
  transition: 1s;
}
.logo__svg:hover .logo--letter{
  opacity: 1;
}

.logo__svg:hover{
  cursor: var(--cursor-orange);
}

.logo--letter {
  transition: 1s;

}

.navbar__links__container {
  flex: 1;
  display: flex;
  justify-content: flex-end;

  color: var(--color-white);
  font-family: var(--font-family);
  z-index: 10;


}


.navbar__links {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 12px;
}

.navbar__links > li {
  list-style-type: none;
  padding: 8px 10px;
  white-space: nowrap;
}

.navbar__work p {
  color: var(--color-white);
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 400;
  padding: 30px 0
}

.navbar__work--us {
  margin-right: 1.5rem;
  position: absolute;
  right: 0;
}

.navbar__work--email {
  visibility: hidden;
  opacity: 0;

  position: absolute;
}

.navbar__work--copy {
  margin-right: 1rem;
  text-align: right;
}

.navbar__work--triangle {
  width: 12px;
  height: 12px;
  background: var(--color-white);
  position: relative;
}

.navbar__work--triangle div {
  width: 20px;
  height: 12px;
  background: black;
  rotate: 45deg;
  transform: translateY(50%);
  position: absolute;
  left: -4px;
  top: -1px
}


.navbar__work--square {
  width: 12px;
  height: 12px;
  background: var(--color-white);

  transition: 1s ease;
}

.navbar__work.active:hover .navbar__work--us {
  cursor: url('../../assets/arrows/orangeDot.svg') 10 10, auto;
  animation: fadeOut .2s forwards ease-in;
}

.navbar__work.active:hover .navbar__work--email {
  cursor: url('../../assets/arrows/orangeDot.svg') 10 10, auto;
  animation: rightIn .1s forwards ease-in;
}
.navbar__work.active:hover .navbar__work--square {
  cursor: url('../../assets/arrows/orangeDot.svg') 10 10, auto;
  border-radius: 50%;
}

@keyframes rightIn {
  0% {right: 0px; visibility: hidden;  opacity: .3;}
  100% {right: 20px; visibility: visible; opacity: 1;}
}

@keyframes fadeOut {
  0% {right: 0; opacity: 1;}
  100%{
    right: 70px; 
    opacity: 0;
    visibility: hidden;
  }
}

/* black text */
.blackNavbar{
  transition: .2s ease;
  background-color: var(--color-white);
}

.blackNavbar h1, .blackNavbar p {
  color: black;
}

.blackNavbar .devise__navbar-square, .blackNavbar .devise__navbar-circle {
  background: black;
  transition:  1s ease;
}

.blackNavbar .content__location--circle,
.blackNavbar .navbar__work--square {
  background: black;
  transition:  1s ease;
}

.blackNavbar .navbar__work--triangle {
  background: black;
}
.blackNavbar .navbar__work--triangle div {
  background: white;
}
.blackNavbar .logo__svg:hover .logo--letter{
  cursor: none;
  fill: black;
}
.blackNavbar .logo__svg:hover .logo--slant{
  cursor: none;
  fill: black;
}


/* @media screen and (max-width: 1200px) {
  .navbar {
    width: 100%;
  }
}   */
@media screen and (max-width: 820px) {
  .navbar{
    display: flex;
    row-gap: 1rem;
    height: 100px;
  }
  .navbar__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 
    "marquee marquee"
    "logo contact"
    ;
    justify-content: space-between;

    row-gap: 1rem;
    padding: 16px 16px 0;

  }

  .navbar__logo {
    grid-area: logo;
    justify-content: flex-start;
    justify-content: flex-start;
    width: fit-content;
  }

  .content__location {
    grid-area: marquee;
    width: 90%; 
  }
  .content__location--circle{
    aspect-ratio: 1 / 1;
  }
  .content__location--hire {

  }

  .navbar__marquee {
    width: 100%;

  }

  .navbar__marquee__container{
    width: 100%;

  }
  .navbar__links {
    column-gap: 0px;
  }
  .navbar__marquee-block{
    width: calc(65px * (var(--total-marquee-items)));
  }

  .logo--slant.isTop {
    animation: moveSlant 500ms linear forwards;

  }
  @keyframes moveSlant {
  0% {transform: translate(0);}
  100% {transform: translate(-50%);}
    
  }
} 

@media screen and (max-width: 480px) {
  .navbar__wrapper {
    padding: 16px 0 0;
  }
  .navbar__links li {
    display: none;
  }

  .content__location {

  }
  .content__location--circle {
    margin: 0;
    margin-right: 4px;
  }
  .content__location--hire {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navbar__links li:nth-last-child(2){
    display: flex;
  }


 


}
