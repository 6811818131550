.method {
  width: 100vw;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  
  margin: 0 auto 5rem;
  position: relative;
  color: var(--color-white);
  padding: 0 32px;


}

.method__content {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: var(--font-family);
  font-weight: 400;
  column-gap: 2em;
  padding: 24px 16px;
  overflow: hidden;

  
  border-top: 1px solid #3F3E3E;
  
  max-height: 82px;
  transition: all 500ms cubic-bezier(0,1,0,1);
}

.method__content.active {
  height: auto;
  max-height: 9999px;
  transition: all 500ms cubic-bezier(1,0,1,0);
}

.method__content:last-child {
  border-bottom: 1px solid #3F3E3E;
}

.method__content:hover {
  border-top: 1px solid var(--color-orange)
}


.method__content--header{
  flex: 1.5;
  font-size: 24px;
  line-height: 34px;
  
}
.method__content--header > p {
  white-space: nowrap;

}

.method__content__middle{
  flex: 3;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

}

.method__content__middle.active {
  height: auto;
}

.content__middle--detail {
  display: flex;
  height: 100%;
  align-items: center;
  color: var(--color-orange);
}



.method__content--description{
  flex: 1.5;
  font-size: 16px;
  line-height: 24px;
}

.method__content--service{
  flex: 1;

  font-size: 16px;
  line-height: 24px;
}

.method--square {
  width: 12px;
  aspect-ratio: 1/1;
  background-color: var(--color-orange);

}
.method__content--number{
  flex: .5;
  display: flex;
  height: fit-content;
  justify-content: center;
  align-items:center;
  font-size: 28px;
  color:#EF4409;
  line-height: 28px;
  column-gap: 1rem;

  position: relative;
}


@media screen and (max-width: 820px) {
  .method__content {
    column-gap: 1rem;
  }

  .method__content--header {
    font-size: 18px;
    line-height: 28px;
    white-space: nowrap
  }

  .method__content--number {
    flex: 1
  }
}

@media screen and (max-width: 480px) {
  .method{
    padding: 0
  }
  .method__content {
    flex-direction: column;
  }
  .method__content--number {
    justify-content: flex-end;
  }

}