.loading__container  {
  width: 100%;
  max-width: var(--max-width);
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;

  margin: 0 auto;

}



.loading__svg{
  color: var(--color-white);
  width: 100%;
  height: auto;
}

.loading__logo--letter {
  animation: letterFadeIn 2000ms ease-in-out forwards;
}

@keyframes letterFadeIn {
  0%{transform: translateY(-100%);}
  50%{transform: translateY(0);}
  100%{transform: translateY(-100%);}
}

/* .loading__container  {
  animation: fadeContainer 500ms ease-out forwards; 
  animation-delay: 1200ms;
} */



/* @keyframes fadeContainer {
  from{
    height: 100vh;}
  to{  
    height: 0;
  }
} */