.roles {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--color-white);
  font-family: var(--font-family);

  padding: 0 32px;
  margin: 128px auto 90px;

  position: relative;
}

.roles__headline {
  display: flex;
  width: 66%;

  position: relative;
}

.roles__headline--role {
  color: var(--color-orange);
  font-size: 12px;
  line-height: 16px;

  position: absolute;
}
.roles__headline--header {
  text-indent: 4rem;
  font-size: 44px;
  line-height: 52px;

  margin-bottom: 52px;
}

.roles__container{
  width: 66%;

}

.roles__content{
  color: var(--color-white);
  font-family: var(--font-family);
  font-size: 20px;  
  font-weight: 400;
  line-height: 28px;

  border-bottom: 1px solid #3F3E3E;
  padding-top: 16px;
}


.roles__content--container{
  display: flex;
  margin-bottom: 1rem;
  column-gap: 2rem;

}

.roles__content--name{
  flex:1
}
.roles__content--stage{
  flex:1;
  font-size: 14px;
  line-height: 20px;
}

@media screen and (max-width: 820px) {
  .roles {
    width: 100%;
    padding: 0 16px;
  }

  .roles__headline {
    width: 100%;
  }


  .roles__container{ width: 100%;}
}
@media screen and (max-width: 480px) {
  .roles__headline--header {
    font-size: 32px;
    line-height: 40px;
  }

  .roles__content--container{
    flex-direction: column;
    row-gap: 4px;
  }
  

  .roles__content {
    font-size: 16px;
    line-height: 22px;
  }

.roles__content--stage{
  font-size: 12px;
  line-height: 18px;
}
}