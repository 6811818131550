.hero__container  {
  color:var(--color-white);
  width: 100vw;
  max-width: var(--max-width);
  height: 75vh;

  margin: 110px auto 0;
  display: flex;
  align-items: center;
  
  opacity: 0;
  position: relative;
  overflow: hidden;
  animation: fadeIn 500ms linear forwards
}

@keyframes fadeIn {
  0%{opacity: 0;}
  100%{opacity: 1;}
}

.hero__headline__container{
  width: 80%;
  padding-left:  32px;
}
.hero__headline {
  max-width: 85%;
  font-size: 64px;
  line-height: 70px;
  font-weight: 400;
  font-family: var(--font-family), Helvetica, Arial, sans-serif;
}

.hero__logo__container{
  width: 100%;
  height: 90%;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: repeat(12, 1fr);
  mix-blend-mode: difference;

  position: absolute;
  inset: 0
}

.hero__logo--image {
  width: 100%;
  object-fit: contain;

  opacity: 0;
  transition: 1s;
  animation: fade-in 2000ms ease 1000ms forwards;
}

@keyframes fade-in {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }

}

#logo__0 {
  grid-row: 3 / 5;
  grid-column: 1 / 4;
}
#logo__1{
  grid-row: 5 / 7;
  grid-column: 5 / 7;
  transform: translate(10%, -5%);
  padding: 0, 50px;
}
#logo__2{
  grid-row: 3 / 5;
  grid-column: 8 / 10;
}
#logo__3{
  grid-row: 5 / 7;
  grid-column: 13 / 17;
}
#logo__4 {
  grid-row: 3 / 5;
  grid-column: 19 / 22;
}
#logo__5 {
  grid-row: 4 / 6;
  grid-column: 23 / 26;
}
#logo__6 {
  height: 90%;
  grid-row: 6 / 9;
  grid-column: 18 / 22;
  transform: translateX(10%);
}
#logo__7{
  grid-row: 10 / 12;
  grid-column: 4 / 7;
}
#logo__8{
  grid-row: 9 / 11;
  grid-column: 8 / 10;
}
#logo__9{
  grid-row: 11 / 13;
  grid-column: 9 / 12;
}
#logo__10{
  grid-row: 9 / 11;
  grid-column: 11 / 14;
  transform: translate(20%, 30%);
}
#logo__11{
  grid-row: 8 / 10;
  grid-column: 17 / 20;
  transform: translate(5%, 25%);
}
#logo__12{
  width: 80%;
  height: 80%;
  grid-row: 10 / 13;
  grid-column:  22 / 25;
}
#logo__13{
  height: 90%;
  grid-row: 11 / 13;
  grid-column: 17 / 21;
  transform: translateY(20%);
  
}

.hero__footer__container {
  display: flex;
  color: var(--color-white);
  font-family: var(--font-family);
  position: absolute;
  bottom: 0;
  right: 137px;
  column-gap: 2em;

}

.hero__footer__container p {
  font-size: 12px;
  line-height: 18px;
}

@media screen and (max-width: 820px) {
  
}

@media screen and (max-width:480px) {
  .hero__container {
    flex-direction: column;
    justify-content: center;
  }
  .hero__headline__container{
    width: 100%;
  }
  .hero__headline{
    font-size: 32px;
    line-height: 40px;
  }
  .hero__logo__container {
    height: 60%;
    position: relative;
    inset: none;
  }
  .hero__footer__container {
    right: 20px;
    bottom: 5%;
    column-gap: .5rem;
  }
}