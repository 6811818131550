:root{
  --total-marquee-items: 33;
}

.logoMarquee__container{
  color: var(--color-white);
  width: calc(var(--total-marquee-items) * 100px);
  max-width: var(--max-width);
  justify-content: center;
  overflow: hidden;

  margin: 8rem auto 0;
  animation: fadeIn 500ms linear forwards;
}

/* @keyframes fadeIn {
  0%{opacity: 0;}
  100%{opacity: 1;}
} */


.logoMarquee__content{
  display: flex;
  animation: scrolling 45s linear infinite;
  column-gap: 5rem;
}


.marquee-item{
  display: flex;
  flex: 1;
  height: 50px;
  align-items: center;
  justify-content: center;
  column-gap: 3rem;
  align-items: center;
}


.logoMarquee__content:hover {
  animation-play-state: paused;
}


.marquee-item img {
  display: flex;
  width: 100px;
  height: 75px;

  object-fit: contain;

}

@keyframes scrolling {
  0% { transform: translateX(); }
  100% { transform: translatex(-100%); }
}




@media screen and (max-width: 820px) {
  .logoMarquee__container {
  margin: 4rem 0 8rem;
  
  }
}

@media screen and (max-width: 480px) {
  .logoMarquee__content{
    column-gap: 1.5rem;
  }
}