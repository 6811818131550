@font-face {
  font-family: 'PP Mori';
  src: url('./assets/fonts/PPMori-Regular.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PP Mori-';
  src: url('./assets/fonts/PPMori-SemiBoldItalic.woff');

  font-weight: 600;
  font-style: italic;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color-bg: #040303;
  --color-white: #FBFBFB;
  --color-orange: #FE4409;
  --font-family:'PP Mori', sans-serif, serif;
  --cursor-black: url(./assets/arrows/black_circle.svg) 26 26, auto;
  --cursor-white: url(./assets/arrows/white_circle.svg) 26 26, auto;
  --cursor-orange: url(./assets/arrows/orangeDot.svg) 26 26, auto;
  --max-width: 1600px
}

body {
  background: var(--color-bg);
  width: 100%;
}

.app__bg {
  background: #040303;
}

.p__ppmori {
  font-family: var(--font-family), Helvetica, Arial, sans-serif;
  color: var(--color-white)
}

.section__padding {
  padding: 2rem 2rem;
}


a { 
  padding: 5px 15px;
  text-decoration: none; color: var(--color-bg)}

a:hover {
  cursor: url('./assets/arrows/orangeDot.svg') 26 26, auto;
}

.linkCursor{   
  cursor: url('./assets/arrows/orangeDot.svg') 26 26, auto;
}

.headerText {
  font-size: 32px;
  line-height: 38px;
  font-family: var(--font-family), Helvetica, Arial, sans-serif;
}

@media screen and (max-width: 820px) {
  .section__padding {
    padding: 2rem
  }
  .headerText {
    font-size: 24px;
    line-height: 30px;
  }
}

@media screen and (max-width: 500px) {
  .section__padding {
    padding: 1rem
  }

}