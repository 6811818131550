.App {
  /* max-width: var(--max-width); */
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  font-family: var(--font-family);
  cursor: var(--cursor-white);
    scroll-snap-type: mandatory;
    -webkit-overflow-scrolling: touch;
  overflow: hidden;

  position: relative;
}

@keyframes popIn {
  from{ transform: translateY(100%);}
  to{transform: translateY(0);}
}



.white_up {
  cursor: url(./assets/arrows/whiteU.svg) 26 0, auto;
}

.white_center {
  cursor: url(./assets/arrows/white_circle.svg) 26 26, auto;
}

.black_center {cursor: url(./assets/arrows/black_circle.svg) 26 26, auto;}

